
import { Component, Prop, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';

import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';

import type Day from '@/modules/common/types/day.type';
import Available from './cards/available.vue';
import SoldOut from './cards/sold-out.vue';
import NoData from './cards/no-data.vue';
import NotAvailable from './cards/not-available.vue';

@Component({
    components: {
        NotAvailable,
        NoData,
        SoldOut,
        Available,
    },
})

export default class RatesCalendarItem extends Vue {
    @inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    @inject(RatesServiceS)
    private ratesService!: RatesService;

    @Prop({
        required: true,
        type: Number,
    })
    private day!: Day;

    private nonClickableClasses: string[] = ['calendar-item__actions'];

    get isPreviousDay() {
        return this.documentFiltersService.isPreviousDay(this.day);
    }

    get isNoData() {
        return this.ratesService.isNoData(this.day);
    }

    get isNotAvailable() {
        return this.ratesService.isNA(this.day, this.hotelId);
    }

    get isSoldOut() {
        return this.ratesService.isSoldOut(this.day, this.hotelId);
    }

    get hotelId() {
        return Number(this.$route.params.hotelId);
    }

    handleClick(e: MouseEvent) {
        const currentTargetElement = e.currentTarget as HTMLElement;
        const targetElement = e.target as HTMLElement;

        const isNonClickable = (element: HTMLElement): any => {
            if (!element.parentElement) {
                return false;
            }

            if (this.nonClickableClasses.includes(element.parentElement.className)) {
                return true;
            }

            if (element.parentElement!.className === currentTargetElement.className) {
                return false;
            }

            return isNonClickable(element.parentElement);
        };

        if (!isNonClickable(targetElement)) {
            this.$router.push({
                name: `${this.$route.name}.day-rate`,
                params: {
                    day: String(this.day),
                    hotelId: String(this.hotelId),
                },
            });
        }
    }
}
